import React from 'react'
import video from '../assets/vollmond innovations (1).mp4'
import Contact from '../../src/footer/contact'
import Formss from '../../src/home/contact-us'
// import Navbar from '../../src/header/web-navbar'
import './/Home.css'
import { Link } from 'react-router-dom' 



const Home = () => {
  return (
<Link to="/" className='link' > 
  <>
        <video autoPlay loop controlsList="nodownload"  muted  style={{width:"100%",height:"100%",marginBottom:"0"}}> 
            <source src={video} type="video/mp4" />
        </video>

        <div className='home-bg-container'>
          <div className='home-text-container'>
            <div className='scroll-text'>
                <h1 className='home-head1'>A SOCIAL ENTREPRISE PROMOTING </h1>
                <p className='home-para1'>SUSTAINABLE INNOVATIONS</p>
            </div> 
          </div>
        </div>

        <div>
            <div className='text-health'>
                <h1 className='home-head2'>Our Focus on Healthcare Technologies</h1>
                <hr className='line'/>
                <p className='home-para2'>We are trying to build reliable and accountable enterprise application that can make meaning full use of  digitalisation</p>
                <button type='button' className='portfolio'>Portfolio</button>
            </div>
            <div><Formss /></div>
            <div><Contact /></div>
        </div>


  </>
</Link>  
  )
}

export default Home


import { Link } from 'react-router-dom'

import Contact from '../footer/contact'
import React from 'react'
import akhilpass from '../assets/akhilpass.webp'
import rajini from '../assets/rajini.webp'
import narendar from '../assets/narendar.webp'
import shivakumar from '../assets/shivakumar.webp'
import santosh from '../assets/santosh.webp'
import venkatreddy from '../assets/venkatreddy.webp'
import sandeep from '../assets/sandeep.webp'
import tarun from '../assets/tharun.webp'   
import vijay from '../assets/vijay.webp'
import mallik from '../assets/mallik.webp'
import aravind from '../assets/aravind.webp'
import shravani from '../assets/shravani.webp'
import first    from '../assets/4.webp'
import second   from '../assets/3.webp'
import third from '../assets/2.webp'
import './promoters.css'


const details = [
   {
    id: 1,
    name: ' Akhil Kolli',
    title: 'Managing Director, CEO',
    imageURL: akhilpass
},
{
    id: 2,
    name: 'Rajani K',
    title: 'Founding Director',
    imageURL: rajini
    
},
{
    id: 3,
    name: 'Narendar Reddy Kokkonda',
    title: 'Managing Director',
    imageURL: narendar
},
{
    id: 4,
    name: 'Shivakumar Reddy Musipatla',
    title: 'Investor',
    imageURL: shivakumar
},
{
    id: 5,
    name: 'Samudrala Santosh',
    title: 'Business development, investor relations',
    imageURL: santosh
},
{
    id: 6,
    name: 'Dr Venkat Reddy Kallem',
    title: 'Chief Medical Office',
    imageURL:  venkatreddy
},
{
    id: 7,
    name: '  Sandeep Reddy',
    title: 'Product Manager',
  imageURL: sandeep
},
{
    id: 8,
    name: 'CHERUKURI SAI THARUN ',
    title: 'Chief Financial Officer',
    imageURL: tarun
},
{
    id: 9,
    name: 'VIJAY MOHAN A',
    title: 'Technology Advisor, Mentor',
    imageURL: vijay
},
{
    id: 10,
    name: 'Ch Mallikarjun Reddy',
    title: 'Team Lead Clinical Operations',
    imageURL: mallik
},
{
    id: 11,
    name: 'Aravind Reddy Alla',
    title: 'Team Lead IT Operations',
    imageURL: aravind
},
{
    id: 12,
    name: ' Sravani G V',
    title: 'Business Operations',
    imageURL: shravani
},
]


const Promoters = () => {
    console.log(details)
  return (
      <Link to='/promoters' className='link'>
    <>
     <div className='promoters-top-container'>
         <h1 className='promoters-heading'>About Us</h1>
         <p className='promoters-para1'>Finding Inspiration in Every Turn</p>
         <p className='promoters-para2'>A Clear and Transparent Corporate structure with dedicated and hard working team is strength.</p>
     </div>
     <div className='p-container'>
     <div > <h1 className='promoters-heading2'>Meet The Team</h1></div>
     <hr className='line'/>
         <div className='imgs'>
         {details.map(detail => (
             <div key={detail.id}>
                 <img className='img' src={detail.imageURL} alt={detail.name}/>
                    <div className='p-text'>
                    <h2 className='p-name'>{detail.name}</h2>
                    <p className='p-title'>{detail.title}</p>
              </div>
                    </div>)

         )}
         <div className='promoter-down-container'>
            <h1>Our Clients</h1>
            <hr className='line'/>
            <img src={first} alt=""  className="logo-img"/>
            <img src={second} alt="" className="logo-img"/>
            <img src={third} alt="" className="logo-img"/>

         </div>

         </div>

     </div>
     <Contact />
    </>
    </Link>
  )
}

export default Promoters

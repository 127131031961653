import React from 'react'
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom'
import topbg from '../assets/topbg.webp'
import medexperts from '../assets/medexperts.webp'
import clinivault from '../assets/clinivault.webp'
import fhir from '../assets/fhir.png'
import neonatAI from '../assets/neonatAI.png'
import docsteth from '../assets/docsteth.png'
import Contact from '../footer/contact.js'

import './Portfolio.css'







const Portfolio = ()=> {
  return (
<Link  to="/portfolio" className="link">
<>
<Grid container>


    <Grid item xs={12} md={5} className="top-container">
        <div className="top-container-2">
            <div></div>
            </div>
       
        <div>
            <h1 className='top-heading'>Healthcare is Our Prime Focus</h1>
            <p className='top-para'>We're a Product based company building a sustainable eco-system for healthcare innovations  India and beyond</p>
        </div>
    </Grid>
    <Grid item  xs={12} md={7}>
        <img src={topbg} alt=" " className='img-top' />
    </Grid>
       
</Grid>






<Grid container columns={12} >
<Grid  item xs={12} md={3}>
                <img src={medexperts} alt='' style={{width:"100%",height:"100%"}}  />
            </Grid>
            <Grid item xs={12} md={3} className="text-bg">
                <h1 className='portfolio-heading'>MedXperts</h1>
                <p className='portfolio-para'> Application for Patients to manage their medical records, clinic visits and telemedicine consultation </p>
                <button className='portfolio-btn'>Learn More</button>
            </Grid>

            <Grid item xs={12} md={3}>
                <img src={clinivault} alt='' style={{width:"100%",height:"100%"}}   />
            </Grid>
            <Grid item xs={12} md={3} className="text-bg">
                <h1  className='portfolio-heading'>CLINIVAULT</h1>
                <p className='portfolio-para'> Hospital Information system, with integrated EHR and ERP. For complete clinical and business automation  </p>
                <button className='portfolio-btn'>Learn More</button>
            </Grid>



            <Grid item xs={12} md={3} className="text-bg" sx={{order:{xs:"1",md:"0"}}}>
                <h1  className='portfolio-heading'>FHIRVAULT</h1>
                <p className='portfolio-para'> Complete infrastructure and application platform for exchange of healthcare care data </p>
                <button className='portfolio-btn'>Learn More</button>
            </Grid>
            <Grid item xs={12} md={3} sx={{order:{xs:"0",md:"1"}}}>
                <img src={fhir} alt='' className=' color2' style={{width:"100%",height:"100%",backgroundColor:" rgb(222, 11, 11)"}} />
            </Grid>

            <Grid item xs={12} md={3} className="text-bg"  sx={{order:{xs:"3",md:"2"}}} >
                <h1  className='portfolio-heading'>NeonatAI</h1>
                <p className='portfolio-para'> Health care personnel working in Neonatal Intensive Care Units (NICU) take care of sick and very fragile babies. We are trying to make neonatal care easy and accurate with our application. </p>
                <button className='portfolio-btn'>Learn More</button>
            </Grid>
            <Grid item xs={12} md={3}  sx={{order:{xs:"2",md:"3"}}}>
                <img src={neonatAI} alt='' style={{width:"100%",height:"100%",backgroundColor:"#fff"}}  />
            </Grid>

            
<Grid item xs={12} md={7} style={{order:"4"}}>
                <img src={docsteth} alt='' style={{width:"100%",height:"100%",backgroundColor:"rgb(155,203,108)"}}  />
            </Grid>
            <Grid item xs={12} md={5} className="text-bg"  style={{order:"5"}}>
                <h1  className='portfolio-heading'>Docsteth</h1>
                <p className='portfolio-para'>A Professional networking platform exclusively for healthcare providers, Connect, share knowledge, learn and practice </p>
                <button className='portfolio-btn'>Learn More</button>
            </Grid>


           



    
</Grid>
{/* <div className='t-container'>
        <div className='t-container'>
            <Grid xs={4}>
                <img src={medexperts} alt='' className='imgs-container'   />
            </Grid>
            <Grid xs={4} className="text-bg">
                <h1>MedXperts</h1>
                <p> Application for Patients to manage their medical records, clinic visits and telemedicine consultation </p>
                <button>Learn More</button>
            </Grid>
        </div>
        
        <div className='t-container'>
            <Grid xs={4}>
                <img src={clinivault} alt='' className='imgs-container'  />
            </Grid>
            <Grid xs={4} className="text-bg">
                <h1>CLINIVAULT</h1>
                <p> Hospital Information system, with integrated EHR and ERP. For complete clinical and business automation  </p>
                <button>Learn More</button>
            </Grid>
        </div>

</div> */}

{/* <div className='t-container'>
        <div className='t-container'>
          
            <Grid xs={4} className="text-bg">
                <h1>FHIRVAULT</h1>
                <p> Complete infrastructure and application platform for exchange of healthcare care data </p>
                <button>Learn More</button>
            </Grid>
            <Grid xs={4} className='bottom-img-container'>
                <img src={fhir} alt='' className='imgs-container1 color2' />
            </Grid>
        </div>
        
        <div className='t-container'>
          
            <Grid xs={4}>
                <h1>NeonatAI</h1>
                <p> Health care personnel working in Neonatal Intensive Care Units (NICU) take care of sick and very fragile babies. We are trying to make neonatal care easy and accurate with our application. </p>
                <button>Learn More</button>
            </Grid>
            <Grid xs={4} className='bottom-img-container'>
                <img src={neonatAI} alt='' className='imgs-container1'  />
            </Grid>
        </div>

</div> */}







<Contact />
</>

</Link>      

    
  )
}

export default Portfolio
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Promoters from './promoters/promoters';
import Navbar from './header/web-navbar'
import Careers from './careers/Careers';
import Portfolio from './portfolio/Portfolio';
import Insights from './Insights/Insights';

import  Home  from './home/Home';

function App() {
  return (
    <div className="App">
      <Router>  
     <Navbar />
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/promoters' component={Promoters}/>
        <Route exact path="/portfolio" component={Portfolio}/>
        <Route exact path="/insights" component={Insights}/>
        <Route exact path="/careers" component={Careers}/>
      </Switch>
    
     
      </Router>
      {/* <Promoters/> */}
      {/* <Contact /> */}
    </div>
  );
}

export default App;

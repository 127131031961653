import React from 'react'
import { Link } from 'react-router-dom'
import Contact from '../footer/contact.js'

import './Careers.css'

const Careers = () => {
  return (
<Link className="link" to="/careers">

    <>
    <div className='career-main-bg'>
      <div className='career-text-container'>
        <h1 className='career-heading'>KICK START  YOUR CAREERWITH US</h1>
        <p className='career-para'>Yes, It will be challenging and exiting to work with us, We design and develop our products with a great passion. Innovate with a management that is compassionate about its employees </p>
        <button className='career-btn'>Search Jobs</button>
      </div>
      <div className='career-sub-container'>
        <h1 className='career-heading2'>FIND THE RIGHT OPPURTUNITY FOR YOUR CAREER</h1>
        <hr className='line'/>
        <p className='career-para2'>Call for Technology developers, Care givers and next generation leaders Your Next job with us will help us grow together</p>
      </div>
    </div>

    <Contact />
    </>
 </Link>   
  )
}

export default Careers
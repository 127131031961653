import React from 'react'
import { Link } from 'react-router-dom'
// import SearchIcon from '@mui/icons-material/Search';
// import SearchBar from "@material-ui-search-bar";
import FilterListIcon from '@mui/icons-material/FilterList';
import Contact from '../footer/contact'
import  SearchBar from './searchbar'
import './Insights.css'
// import DataGridDemo from './Insightstable'
// import { Checkbox } from '@mui/material';
// import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const data=[
  {
    id:1,
    title: 'Tax Filings',
    date: 'jul 05 2022',
    count: '0 items'
  },
  {
    id:2,
    title: 'Monthly performance Analysis', 
    date: 'jul 05 2022',
    count: '0 items'  
  },  
  {
    id:3,
    title: 'Publications and Press Releases',  
    date: 'jul 05 2022',
    count: '0 items'
  },
  { 
    id:4,
    title: 'Finacial Reports',
    date: 'jul 05 2022',
    count: '0 items'
  },
  {
    id:5,
    title: 'AGM',
    date: 'jul 05 2022',
    count: '0 items'
  },
  {
    id:6,
    title: 'ROC Filings',
    date: 'jul 05 2022',
    count: '0 items'
  },
  {
    id:7,
    title: 'FAQs',
    date: 'jul 04 2022',
    count: '0 items'
  },
 

]



const Insights = () => {
  const [state, setState] = React.useState(false)

  const date = new Date();
  
  const [month, day, year]       = [date.getMonth(), date.getDate(), date.getFullYear()];
  console.log(month, day, year);
  
  


  return (
      <Link to="/insights" className="link">
    <div className='insights-bg'>
       <div className='file-background'>
           <div className='file-top-container'>
               <h1>Files</h1>
               {/* <SearchIcon /> */}
               <SearchBar/>
  


           </div>
           <div>
               <div className='file-top-container'>
                   <div style={{display:"flex"}}>
                         <input type="checkbox" id="name" name="name" value="name" className='check-box' onClick={()=>setState(!state)} checked={state}/>
                         <label htmlFor="name" style={{marginLeft:"20px"}}>Name</label>
 
                   </div>
                  
                     <div className='file-sub-container'> 
                       <p>Last updated</p>
                       <FilterListIcon md={{marginLeft:"80px"}} xs={{marginLeft:"40px"}} />
                     </div>
               </div>
           </div>
          
          {data.map(item=>{
            return(
                 
           <><hr className='insight-line' /><div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='checkbox'>
                {/* <div style={{}} > */}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                  <input type="checkbox" id="file-taxing" className='check-box' />
                  {/* <FolderOpenIcon style={{height:"5rem",width:"5rem",color:"rgba(159,143,124,1)"}}/> */}
                  <FolderIcon style={{ height: "2rem", width: "2rem", color: "rgba(159,143,124,1)", marginRight: "20px", marginLeft: "20px" }} sm={{height:"1.5rem",width:"1.5rem",color: "rgba(159,143,124,1)", marginRight: "20px", marginLeft: "20px"}} />

                  <div>
                    <p style={{textAlign:"left"}}>{item.title}</p>
                    <p className='count'>{item.count}</p>
                  </div>

                </div>

                <div className='date-container'>
                  <p>{item.date}</p>
                  <MoreVertIcon sm={{ marginLeft: "80px" }} xs={{marginLeft:"70px"}}  />
                </div>
              </div></>



            )
          })}
          
       
               <div>
               </div>
               
         
       </div>
       </div>
    <Contact />

    {/* <DataGridDemo/> */}
  </Link>
  )
}

export default Insights
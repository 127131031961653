import React from "react";

import logo from '../assets/logo.png'
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from './header.js'


// const theme = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 900,
//       lg: 1200,
//       xl: 1536,
//     },
//   },
// });


const useStyles = makeStyles((theme) => ({
  appbar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
boxShadow: '1px 1px 5px 0px rgba(150,139,150,1)',
  },
  navlinks: {
    marginLeft:"auto",
    display: "flex",
    alignItems: "center",
  
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    color: "black",
  },
  link: {
    [theme.breakpoints.up('sm')]: {
    textDecoration: "none",
    color: "#1A2B6D",
    fontSize: "13px",
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&:hover": {
      color: "red",
      transition: "all 0.5s ease",
    },
  },
  [theme.breakpoints.up('md')]: {
    textDecoration: "none",
    color: "#1A2B6D",
    fontSize: "16px",
    fontWeight: 600,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    "&:hover": {
      color: "red",
      transition: "all 0.5s ease",
    },
  },
  [theme.breakpoints.up('lg')]: {
    textDecoration: "none",
    color: "#1A2B6D",
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "red",
      transition: "all 0.5s ease",
    },
  },
},

  title: {
    [theme.breakpoints.up('xs')]: {
      color: "#071f4e",
      marginLeft:"auto",
      fontSize:"13px",
      display:"flex",
      textAlign:"left",
      paddingLeft:"10px",
      fontWeight:600,
    },
    [theme.breakpoints.up('sm')]: {
      color: "#071f4e",
      marginLeft:"auto",
      fontSize:"14px",
      display:"flex",
      textAlign:"left",
      paddingLeft:"10px",
      fontWeight:800,
    },
    [theme.breakpoints.up('md')]: {
      color: "#071f4e",   
      marginLeft:"auto",
      fontSize:"18px",  
      display:"flex",
      textAlign:"left",
      paddingLeft:"10px",
      fontWeight:"bold",
    },
  },
  imagestyle: {
    [theme.breakpoints.up('xs')]: {
    width:'45px',
    },
    [theme.breakpoints.up('sm')]: {
    width:'50px',     
    },
    [theme.breakpoints.up('md')]: {
      width:'60px',     
      },
    [theme.breakpoints.up('lg')]: {
      width:'70px',

  },
},
  
}
 
));
 
function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <AppBar position="relative" elevation={0} className={classes.appbar} id="homesection">
      <CssBaseline />
      <Toolbar
        style={{
          display: "flex",
          backgroundColor: "transparent !important",
          width: "100%",
        }}
      >
        <img
          src={logo}
          alt="logo"
          className={classes.imagestyle}
        />
        <span >
        <span  className={classes.title}>VOLLMOND <br/>INNOVATIONS</span>
        </span>
       
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
            <Link to="/promoters" className={classes.link}>
             Promoters
            </Link>
            <Link to='/portfolio' className={classes.link}>
              Portfolio
            </Link>
            <Link to="/insights" className={classes.link}>
        Insights
            </Link>
            <Link to="/careers" className={classes.link}>
                Careers
            </Link>
            {/* <Link to='/contact' className={classes.link}>
              Contact Us
            </Link> */}
            <a href="/#footer" className={classes.link}>
                Contact 
              </a>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;